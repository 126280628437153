const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

export const modules = {
  ASSIGN_PATIENT: { active: false },
}

let firebaseInstance;

// A reference to the imported firebase libraries
let _Firebase;

export const getFirebase = (initialiseApp, afterInit) => {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebaseInstance = initialiseApp(config);

  _Firebase = afterInit()

  const { _Auth } = _Firebase
  const { getAuth, setPersistence, browserSessionPersistence} = _Auth

  const auth = getAuth()
  setPersistence(auth, browserSessionPersistence)

  return firebaseInstance;
};

export const getFirestoreLib = () => {
  const { _Firestore } = _Firebase
  return _Firestore;
};

export const getAuthLib = () => {
  const { _Auth } = _Firebase
  return _Auth;
};

export const getFireStoreDB = () => {
  const { _Firestore } = _Firebase
  const { getFirestore } = _Firestore;
  return getFirestore('australia');
}

export const getFireStoreDocQuery = (collection, id, doGetDoc = true) => {
  const { _Firestore } = _Firebase
  const { getDoc, doc, getFirestore } = _Firestore;
  const firestore = getFirestore('australia');
  return doGetDoc
    ? getDoc(doc(firestore, collection, id))
    : doc(firestore, collection, id);
}

export const getFunctionCallable = (functionName) => {
  const { _Functions } = _Firebase
  const { getFunctions, httpsCallable } = _Functions
  const functions = getFunctions(firebaseInstance, process.env.GATSBY_FUNCTIONS_REGION)
  return httpsCallable( functions, functionName)
}

export const getFunctions = () => {
  const { _Functions } = _Firebase
  return _Functions
};

export const logAnalytics = (label, details) => {
  const { _Analytics } = _Firebase
  const { getAnalytics, logEvent } = _Analytics
  const analytics = getAnalytics()
  logEvent(analytics, label, details);
};
