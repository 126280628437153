import React, { useEffect, useMemo, useState } from "react"
import { getFirebase, logAnalytics } from "../../lib/firebase"
import { FirebaseProvider } from "./firebase-context"
import useLocalStorage from "../../lib/use-localstorage"

function logDev(msg, obj = null) {
  process.env.NODE_ENV === "development" &&
    console.log(`%cDEV----> ${msg}`, `color:green`, obj || '')
}

export default function WithFirebase({ children }) {
  const [currentLoggedInPractice, setCurrentLoggedInPractice] = useLocalStorage(
    "RACGP_CURRENT_LOGGED_IN_PRACTICE"
  )
  const [firebaseInitialised, setFirebaseInitialised] = useState(false)
  const [auth, setAuth] = useState(null)
  const [userData, setUserData] = useState(null)
  const [currentPractice, setCurrentPractice] = useState(null)

  const parseLoggedInPractice = useMemo(() => {
    let parseCurrentLoggedInPractice
    try {
      parseCurrentLoggedInPractice = currentLoggedInPractice
        ? JSON.parse(currentLoggedInPractice)
        : null
    } catch (e) {}

    return parseCurrentLoggedInPractice
  }, [currentLoggedInPractice])

  useEffect(() => {
    let removeOnAuthStateChanged = () => {}

    // Firebase needs to be init'd inside a component
    // Apparently the Firebase init code references the `window` object.
    // But Gatsby build() runs in a Node environment - no `window` exists.
    // https://kyleshevlin.com/firebase-and-gatsby-together-at-last
    Promise.all([
      import("firebase/app"),
      import("firebase/firestore"),
      import("firebase/auth"),
      import("firebase/analytics"),
      import("firebase/performance"),
      import("firebase/functions"),
    ]).then(([_App, _Firestore, _Auth, _Analytics, _Performance, _Functions]) => {
      const { initializeApp } = _App
      const { getFirestore, doc, getDoc, onSnapshot } = _Firestore
      const { 
        getAuth,
        onAuthStateChanged
      } = _Auth
      const { getAnalytics, setUserId, setUserProperties } = _Analytics
      const { getPerformance } = _Performance

      getFirebase(initializeApp, () => {
        return { _App, _Firestore, _Auth, _Analytics, _Performance, _Functions }
      })
      
      const firestore = getFirestore('australia')
      
      if (window !== undefined && process.env.NODE_ENV === "development") {
        window.firestore = firestore
      }

      logDev("Setting up firebase")

      const analytics = getAnalytics()
      const performance = getPerformance()
      const auth = getAuth()

      let unsubUserData = () => {}
      let unsubCurrentPractice = () => {}
      
      removeOnAuthStateChanged = onAuthStateChanged(auth, (user) => {
        logDev(">>>Firebase onAuthStateChange() called. User: ", user)
        if (user) {
          logDev("Subcribing to user data")
          // subscribe to changes in user data with onSnapShot.
          unsubUserData = onSnapshot(doc(firestore, "users", user.uid), (userDoc) => {
            let data = { id: userDoc.id, ...userDoc.data() }
            logDev("User data changed", data)
            setUserData(data)
            setUserId(analytics, user.uid)
            setUserProperties(analytics, { user_id: user.uid })
            const currentPracticeId =
                parseLoggedInPractice?.id || data.currentPracticeId || null
            logDev("Current Practice ID", currentPracticeId)
            
            if (currentPracticeId) {
              unsubCurrentPractice()
              unsubCurrentPractice = onSnapshot(doc(firestore, "practices", currentPracticeId), (practiceDoc) => {
                let prac = {
                  id: practiceDoc.id,
                  ...practiceDoc.data(),
                }
                logDev("Current practice changed", prac)
                setCurrentPractice(prac)
              })
            } else {
              setCurrentPractice(null)
              unsubCurrentPractice()
            }
          })
          
        } else {
          setCurrentLoggedInPractice("")
          logDev("User logged out")

          setUserData(null)
          unsubUserData = () => {}

          setCurrentPractice(null)
          unsubCurrentPractice = () => {}
        }

        setAuth(user)
        setFirebaseInitialised(true)
      })
      // Window reference to the analytics tracking event,
      // used in some cases.
      window.hh_analytics = {
        logEvent: (label, details) => {
          logAnalytics(label, details)
        }
      }
    })
    return () => {
      removeOnAuthStateChanged()
    }
  }, [])

  return (
    <FirebaseProvider
      value={{
        user: auth,
        firebaseInitialised,
        userData,
        currentPractice,
        currentLoggedInPractice: parseLoggedInPractice,
      }}
    >
      {children}
    </FirebaseProvider>
  )
}
